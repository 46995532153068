import React from 'react'
import Box from 'components/Box'
import Container from 'components/Container'
import PasswordForgotForm from 'components/PasswordForgotForm'
import Text from 'components/Text'
import useEventPageVisited from 'hooks/use-event-page-visited'
import * as auth from 'libs/auth'
import Utility from 'templates/Utility'

export default function ForgotPassword() {
  useEventPageVisited('Forgot password')
  const [isSuccess, setIsSuccess] = React.useState(false)

  function handleSuccess() {
    setIsSuccess(true)
  }

  return (
    <Utility title="Forgot password" className="ForgotPassword">
      <Container flush size="medium">
        {!isSuccess ? (
          <>
            <Text element="p">{auth.FORGOT_PASSWORD_INTRODUCTION_COPY}</Text>
            <Container flush size="small">
              <PasswordForgotForm onSuccess={handleSuccess} />
            </Container>
          </>
        ) : (
          <Box>
            <Text align="center" element="p" flush>
              {auth.FORGOT_PASSWORD_SUCCESS_COPY}
            </Text>
          </Box>
        )}
      </Container>
    </Utility>
  )
}
